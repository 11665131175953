<template>
  <vue-custom-scrollbar
    class="scroll-area"
    :settings="{ suppressScrollY: false, suppressScrollX: false, wheelPropagation: false }"
    :style="`height: ${maxHeight}; width: ${maxWidth};`"
    @ps-scroll-y="scrollHanleY">
    <slot></slot>
  </vue-custom-scrollbar>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

export default {
  name: 'OrganizzeScrollbar',
  props: {
    maxHeight: {
      type: String,
      default: 'auto',
      required: false
    },

    maxWidth: {
      type: String,
      default: 'auto',
      required: false
    }
  },
  directives: {},
  components: { vueCustomScrollbar },
  data: () => ({}),
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {
    scrollHanleY () {}
  },
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  .scroll-area {
    position: relative;
    margin: auto;
  }
</style>